.App {
  margin: 3%;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header {
  margin: 4% 2%;
  text-align: center;
}

.bgvi {
  background-color:rgb(97, 50, 205);
  color: aliceblue;
}

.even {
  background-color: yellowgreen;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}
